
.content {
    display: flex;
}

.title {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
}

.item {
    position: relative;
    margin-right: 16px;
}

.selectIcon {
    position: absolute;
    right: 6px;
    bottom: 4px;
    font-weight: 700;
    font-size: 14px;
    pointer-events: none;
}